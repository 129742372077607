import {
  ButtonDS,
  CheckBox,
  DialogModalDS,
  NumericInputDS,
  PageDS,
  RefreshOutlined,
  SelectFuel,
  Spacer,
  ToastContainer,
  dateFormatterDayMonthYearAndHourMinute2Digits,
  triggerToast,
} from "@qivia/ui";
import { TextCapitalized } from "@qivia/ui/src/designSystem/components/text/TextCapitalized";
import { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { VerificationStatusType } from "./supportingDocumentsApi";
import {
  getSupportingDocumentAsync,
  selectGetSupportingDocument,
  selectIsSupportingDocumentPdf,
  selectSupportingDocumentDataMessageError,
  selectTransactionsForVerificationList,
  selectUpdateSupportingDocumentStatus,
  selectUpdateTransactionsCategoryStatus,
  supportingDocumentsVerificationInProgressAsync,
  updateSupportingDocumentDataAsync,
  updateTransactionCategory,
} from "./supportingDocumentsSlice";
import { selectAdminUser } from "./homeSlice";

type SupportingDocumentRouteParams = { id: string };

interface ComponentRotateProps {
  readonly $rotate: number;
}

export const SupportingDocument = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<SupportingDocumentRouteParams>();
  const adminUser = useAppSelector(selectAdminUser);
  const [isChangeCategoryDialogVisible, setIsChangeCategoryDialogVisible] =
    useState(false);

  const currentSupportingDocument = useAppSelector(selectGetSupportingDocument);
  const isCurrentSupportingDocumentPdf = useAppSelector(
    selectIsSupportingDocumentPdf,
  );
  const transactionsForVerificationList = useAppSelector(
    selectTransactionsForVerificationList,
  );

  const updateSupportingDocumentDataMessageError = useAppSelector(
    selectSupportingDocumentDataMessageError,
  );
  const updateSupportingDocumentStatus = useAppSelector(
    selectUpdateSupportingDocumentStatus,
  );
  const updateTransactionCategoryAsyncStatus = useAppSelector(
    selectUpdateTransactionsCategoryStatus,
  );

  const transaction = useMemo(
    () =>
      transactionsForVerificationList.find((transaction) => {
        return transaction.uuid === params.id;
      }),
    [params.id, transactionsForVerificationList],
  );

  const [displayError, setDisplayError] = useState<boolean>(false);
  const [verificationStatus, setVerificationStatus] =
    useState<VerificationStatusType | null>(null);
  const [quantity, setQuantity] = useState<number | "">(
    transaction?.quantity ?? "",
  );
  const [pricePerLiter, setPricePerLiter] = useState<number | "">(
    transaction?.pricePerLiter ?? "",
  );
  const [mileage, setMileage] = useState<number | "">(
    transaction?.mileage ?? "",
  );
  const [fuel, setFuel] = useState<string | null>(transaction?.fuel ?? null);

  const [receiptRotDeg, setReceiptRotDeg] = useState<number>(0);

  const isFuel = useMemo(() => {
    return (
      transaction &&
      (transaction.category === "FUEL_AUTOMATON" ||
        transaction.category === "SERVICE_STATION")
    );
  }, [transaction]);

  const resetValue = useCallback(() => {
    setQuantity("");
    setPricePerLiter("");
    setFuel(null);
    setMileage("");
    setVerificationStatus(null);
  }, []);

  const isCurrentSupportingDocumentDataValidForSubmit = useCallback(() => {
    const isFullData = quantity && pricePerLiter && fuel;
    if (isFullData && verificationStatus) {
      return false;
    }
    return isFullData || !!verificationStatus;
  }, [fuel, pricePerLiter, quantity, verificationStatus]);

  const handleOpenChangeCategoryDialog = useCallback(() => {
    setIsChangeCategoryDialogVisible(true);
  }, []);

  const handleCloseChangeCategoryDialog = useCallback(() => {
    setIsChangeCategoryDialogVisible(false);
  }, []);

  useEffect(() => {
    if (!transaction && transactionsForVerificationList.length > 0) {
      navigate(
        `/home/supporting_documents/${transactionsForVerificationList[0].uuid}`,
      );
    }
  }, [navigate, transaction, transactionsForVerificationList]);

  useEffect(() => {
    if (
      params.id === undefined ||
      transactionsForVerificationList.length === 0
    ) {
      return navigate("/home/supporting_documents");
    }
    void dispatch(getSupportingDocumentAsync(params.id));
  }, [dispatch, navigate, params.id, transactionsForVerificationList.length]);

  useEffect(() => {
    if (updateSupportingDocumentDataMessageError)
      triggerToast(t("supportingDocument.errorMessage"), "error");
  }, [t, updateSupportingDocumentDataMessageError]);

  useEffect(() => {
    if (updateSupportingDocumentStatus === "success") {
      triggerToast(t("supportingDocument.update.success") || "", "valid");
    } else if (updateSupportingDocumentStatus === "failed") {
      triggerToast(t("supportingDocument.update.failure") || "", "error");
    }
  }, [updateSupportingDocumentStatus, t, dispatch]);

  useEffect(() => {
    if (updateTransactionCategoryAsyncStatus === "success") {
      triggerToast(
        t("supportingDocuments.updateCategory.success") || "",
        "valid",
      );
    } else if (updateTransactionCategoryAsyncStatus === "failed") {
      triggerToast(
        t("supportingDocuments.updateCategory.failure") || "",
        "error",
      );
    }
    setIsChangeCategoryDialogVisible(false);
  }, [updateTransactionCategoryAsyncStatus, t, dispatch]);

  const close = useCallback(() => {
    navigate("/home/supporting_documents");
  }, [navigate]);

  const submit = useCallback(() => {
    if (!currentSupportingDocument) {
      return;
    }
    if (!isCurrentSupportingDocumentDataValidForSubmit()) {
      setDisplayError(true);
    } else {
      setDisplayError(false);
      void dispatch(
        updateSupportingDocumentDataAsync({
          quantity: quantity === "" ? null : quantity,
          pricePerLiter: pricePerLiter === "" ? null : pricePerLiter,
          fuel,
          verificationStatus: verificationStatus ?? "VERIFIED",
          supportingDocumentUuid: currentSupportingDocument.uuid,
          mileage: mileage || null,
        }),
      );
      void dispatch(
        supportingDocumentsVerificationInProgressAsync({
          userName: adminUser?.name ?? "",
        }),
      );
      resetValue();
    }
  }, [
    adminUser?.name,
    currentSupportingDocument,
    dispatch,
    fuel,
    isCurrentSupportingDocumentDataValidForSubmit,
    pricePerLiter,
    quantity,
    resetValue,
    verificationStatus,
    mileage,
  ]);

  const handleChangeTransactionCategoryToWash = useCallback(() => {
    if (transaction?.uuid) {
      void dispatch(
        updateTransactionCategory({
          transactionUuid: transaction.uuid,
          newCategory: "WASH",
        }),
      );
    }
  }, [dispatch, transaction]);

  if (!currentSupportingDocument || !transaction) {
    return;
  }

  return (
    <PageDS
      withoutSupport={true}
      toaster={<ToastContainer />}
      title={""}
      titleElement={{
        closeButtonAction: close,
        button: (
          <ButtonDS
            disabled={!isCurrentSupportingDocumentDataValidForSubmit()}
            text={t("supportingDocument.submit")}
            format={"hug"}
            buttonType={"primary"}
            onClick={submit}
          />
        ),
      }}
    >
      <StyledContainer>
        <DialogModalDS
          visible={isChangeCategoryDialogVisible}
          onClose={handleCloseChangeCategoryDialog}
          title={t("supportingDocuments.dialog.title")}
          width={40}
          bottomButton={
            <ButtonDS
              text={t("supportingDocuments.button.title")}
              format={"fill"}
              buttonType={"primary"}
              onClick={handleChangeTransactionCategoryToWash}
            />
          }
        >
          <TextCapitalized>
            {t("supportingDocuments.dialog.description")}
          </TextCapitalized>
        </DialogModalDS>
        <StyledLeftColumn>
          <StyledRow>
            <ButtonDS
              onClick={() => {
                setReceiptRotDeg((prev) => (prev + 90) % 360);
              }}
              format="hug"
              buttonType="primary"
              singleIcon={{ icon: <RefreshOutlined />, size: "S" }}
            />
            <Spacer x={1} />
            {transaction.category === "SERVICE_STATION" && (
              <ButtonDS
                onClick={handleOpenChangeCategoryDialog}
                text={t("supportingDocuments.button.title")}
                format="hug"
                buttonType="primary"
              />
            )}
          </StyledRow>
          <Spacer y={1} />
          <TextCapitalized>
            {t("supportingDocument.category")}
            {t(`supportingDocuments.category.${transaction.category}`)}
          </TextCapitalized>
          <Spacer y={2} />
          <TextCapitalized>
            {t("supportingDocument.amount")}
            {transaction.amount.value / 100 + " " + transaction.amount.currency}
          </TextCapitalized>
          {quantity && pricePerLiter && (
            <>
              <Spacer y={1} />
              <StyledRow>
                <TextCapitalized>
                  {t("supportingDocument.calcul")}
                </TextCapitalized>
                {(quantity * pricePerLiter).toFixed(2)}
              </StyledRow>
            </>
          )}
          <Spacer y={2} />
          <TextCapitalized>
            {t("supportingDocument.executionDate")}
            {dateFormatterDayMonthYearAndHourMinute2Digits(
              new Date(transaction.executionDate),
            )}
          </TextCapitalized>
          <Spacer y={2} />
          <TextCapitalized>
            {t("supportingDocument.merchantName")}
            {transaction.merchantName}
          </TextCapitalized>
          <Spacer y={2} />
          <TextCapitalized>
            {t("supportingDocument.merchantLocation")}
            {transaction.merchantCity}, {transaction.merchantCountry}
          </TextCapitalized>
          <Spacer y={2} />
          {!isFuel ? (
            <>
              <CheckBox
                text={t("supportingDocument.verified")}
                onClick={() =>
                  verificationStatus === "VERIFIED"
                    ? setVerificationStatus(null)
                    : setVerificationStatus("VERIFIED")
                }
                state={
                  verificationStatus === "VERIFIED" ? "CHECKED" : "UNCHECKED"
                }
              />
              <Spacer y={1} />
            </>
          ) : (
            <>
              <TextCapitalized>{t("supportingDocument.fuel")}</TextCapitalized>
              <SelectFuel
                value={fuel}
                onChange={(value) => setFuel(value)}
                error={displayError && !fuel && !verificationStatus}
              />
              <Spacer y={1} />
              <TextCapitalized>
                {t("supportingDocument.quantity")}
              </TextCapitalized>
              <NumericInputDS
                value={quantity}
                update={(value) => setQuantity(value)}
                floatNumber
                adornent={"L"}
                onKeyDown={(e) => e.key === "Enter" && submit()}
                error={
                  (displayError &&
                    quantity === "" &&
                    !verificationStatus &&
                    t("supportingDocuments.emptyInput")) ||
                  undefined
                }
              />

              <TextCapitalized>
                {t("supportingDocument.pricePerLiter")}
              </TextCapitalized>
              <NumericInputDS
                value={pricePerLiter}
                update={(value) => setPricePerLiter(value)}
                floatNumber
                adornent={t("supportingDocument.currency")}
                onKeyDown={(e) => e.key === "Enter" && submit()}
                error={
                  (displayError &&
                    pricePerLiter === "" &&
                    !verificationStatus &&
                    t("supportingDocuments.emptyInput")) ||
                  (quantity < pricePerLiter &&
                    t("supportingDocuments.pricePerLiterSuperiorToQuantity")) ||
                  undefined
                }
              />
            </>
          )}
          <CheckBox
            text={t("supportingDocument.unreadable")}
            onClick={() =>
              verificationStatus === "UNREADABLE"
                ? setVerificationStatus(null)
                : setVerificationStatus("UNREADABLE")
            }
            state={
              verificationStatus === "UNREADABLE" ? "CHECKED" : "UNCHECKED"
            }
          />
          <Spacer y={1} />
          <CheckBox
            text={t("supportingDocument.missing")}
            onClick={() =>
              verificationStatus === "MISSING"
                ? setVerificationStatus(null)
                : setVerificationStatus("MISSING")
            }
            state={verificationStatus === "MISSING" ? "CHECKED" : "UNCHECKED"}
          />
          <Spacer y={1} />
          <CheckBox
            text={t("supportingDocument.invalid_document")}
            onClick={() =>
              verificationStatus === "INVALID_DOCUMENT"
                ? setVerificationStatus(null)
                : setVerificationStatus("INVALID_DOCUMENT")
            }
            state={
              verificationStatus === "INVALID_DOCUMENT"
                ? "CHECKED"
                : "UNCHECKED"
            }
          />
          <Spacer y={2} />

          {isFuel && (
            <NumericInputDS
              label={`${t("supportingDocument.mileage")} ${transaction.mileage ? t("supportingDocument.lastKnownValue") + " :" + transaction.mileage + ")" : ""}`}
              value={mileage}
              update={(value) => setMileage(value)}
              onKeyDown={(e) => e.key === "Enter" && submit()}
              error={
                (displayError &&
                  (mileage || 0) < (transaction.mileage || 0) &&
                  t("supportingDocuments.badMileageValue")) ||
                undefined
              }
            />
          )}
        </StyledLeftColumn>
        <Spacer x={1} />
        {isCurrentSupportingDocumentPdf ? (
          <StyledIframe>
            <iframe
              src={currentSupportingDocument.presignedUrl}
              title={currentSupportingDocument.fileName}
            />
          </StyledIframe>
        ) : (
          <StyledRightColumn>
            <StyledImage
              src={currentSupportingDocument.presignedUrl}
              $rotate={receiptRotDeg}
            />
          </StyledRightColumn>
        )}
      </StyledContainer>
    </PageDS>
  );
};

const StyledRow = styled.div`
  display: flex;
  color: red;
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledLeftColumn = styled.div`
  max-width: 50%;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const StyledImage = styled.img<ComponentRotateProps>`
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  transform: ${(props) => `rotate(${props.$rotate}deg)`};
`;

const StyledRightColumn = styled.div`
  width: fit-content;
  height: fit-content;
  max-height: 100%;
  overflow: auto;
`;

const StyledIframe = styled.div`
  width: 100%;
  max-width: 50%;
  height: 100vh;
  iframe {
    width: 100%;
    height: 100%;
  },
`;
