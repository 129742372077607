import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { Spacer } from "../Spacer";
import { SideBarIcon } from "./SideBarIcon";
import { SideBarItem } from "./SideBarItem";
import { MenuOptions } from "../Menu/MenuOptions";
import { useTranslation } from "react-i18next";
import {
  AddOutlined,
  LogoutOutlined,
  SettingsSuggestOutlined,
} from "../../materialUi/materialUi";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import { TextCapitalized } from "../../../components/text/TextCapitalized";
import { typographies } from "../../../styles/figmaTypographies";
import { colors } from "../../../styles/figmaColors";
import { ButtonDS } from "../Button";
import { SideBarCompanies } from "./SideBarCompanies";
import { Radio } from "../Radio";

export interface SideBarProps {
  sections: Array<{
    route: string;
    iconSrc: JSX.Element;
    title: string;
    newCreation?: boolean;
  }>;
  selectedRoute?: string;
  onChangeRoute: (route: string) => void;
  logout: () => void;
  companies: { name: string; uuid: string }[];
  currentCompany: string;
  onChangeCompany: (companyUuid: string) => void;
  onClickCompanyCreation: () => void;
}

interface StyledHeaderLogoProps {
  $sideBarSize: SideBarSize;
}

type SideBarSize = "S" | "L";

export const SideBar = (props: SideBarProps) => {
  const { t } = useTranslation();
  const [sideBarSize, setSideBarSize] = useState<SideBarSize>("L");
  const [selectedRoute, setSelectedRoute] = useState<string>(
    props.selectedRoute ?? "",
  );
  const [isMenuOptionsCompaniesOpen, setIsMenuOptionsCompaniesOpen] =
    useState<boolean>(false);

  const onClickArrow = () => {
    sideBarSize === "S" ? setSideBarSize("L") : setSideBarSize("S");
  };

  const onClickLogo = () => {
    setSelectedRoute("");
    props.onChangeRoute("");
  };

  const outsideCompaniesMenuOptions = useOutsideClick(() => {
    setIsMenuOptionsCompaniesOpen(false);
  });

  useEffect(() => {
    if (props.selectedRoute && props.selectedRoute !== selectedRoute) {
      setSelectedRoute(props.selectedRoute);
    }
  }, [props.selectedRoute, selectedRoute]);

  return (
    <StyledContainer $sideBarSize={sideBarSize}>
      <StyledTop>
        <StyledMenu ref={outsideCompaniesMenuOptions}>
          <StyledMenuOptionCompanies>
            {isMenuOptionsCompaniesOpen && (
              <>
                {sideBarSize === "L" ? <Spacer x={2} /> : <Spacer x={11} />}
                <StyledColumn>
                  <MenuOptions
                    options={props.companies.map((company) => {
                      return {
                        action: () => {
                          props.onChangeCompany(company.uuid);
                        },
                        label: (
                          <StyledWordBreak>
                            <StyledJustify>
                              <StyledColumn>
                                <StyledCompaniesTitle>
                                  <TextCapitalized>
                                    {t(`menuOption.entities`)}
                                  </TextCapitalized>
                                </StyledCompaniesTitle>
                                <TextCapitalized>
                                  {company.name}
                                </TextCapitalized>
                              </StyledColumn>
                              <Radio
                                isSelected={
                                  props.currentCompany === company.name
                                }
                              />
                            </StyledJustify>
                            <Spacer x={0.25} />
                          </StyledWordBreak>
                        ),
                        icon: (
                          <SideBarCompanies
                            text={company.name}
                            version={"S"}
                            onClick={() => {
                              props.onChangeCompany(company.uuid);
                            }}
                            hasFocusBorder={true}
                          />
                        ),
                      };
                    })}
                    bottomButton={
                      <ButtonDS
                        format={"fill"}
                        buttonType={"secondary"}
                        text={t(`menuOption.entities.add`)}
                        leftIcon={<AddOutlined />}
                        onClick={props.onClickCompanyCreation}
                      />
                    }
                    width="14.1875"
                  />
                </StyledColumn>
              </>
            )}
          </StyledMenuOptionCompanies>
          {/* mode TEST */}
          <SideBarCompanies
            text={props.currentCompany}
            version={sideBarSize}
            onClick={() =>
              setIsMenuOptionsCompaniesOpen(!isMenuOptionsCompaniesOpen)
            }
            hasFocusBorder={isMenuOptionsCompaniesOpen}
          />
        </StyledMenu>
        <Spacer y={1} /> {/* LATER 4 */}
        {props.sections.map((section) => (
          <Fragment key={section.title}>
            <SideBarItem
              label={section.title}
              icon={section.iconSrc}
              version={sideBarSize}
              onClick={() => {
                setSelectedRoute(section.route);
                props.onChangeRoute(section.route);
              }}
              isSelected={section.route === selectedRoute}
              isNotification={section.newCreation || false}
            />
            <Spacer y={0.5} />
          </Fragment>
        ))}
        <Spacer y={2.125} />
      </StyledTop>
      <StyledMenu>
        <SideBarItem
          label={"menuOption.parameters"}
          icon={<SettingsSuggestOutlined />}
          version={sideBarSize}
          onClick={() => {
            props.onChangeRoute("users");
          }}
          isSelected={false}
          isNotification={false}
        />
        <SideBarItem
          label={"menuOption.logout"}
          icon={<LogoutOutlined />}
          version={sideBarSize}
          onClick={props.logout}
          isSelected={false}
          isNotification={false}
        />
        <Spacer y={0.5} />
        <StyledSideBarIcon>
          <SideBarIcon
            size={sideBarSize}
            onClickArrow={() => onClickArrow()}
            onClickLogo={() => {
              onClickLogo();
            }}
          />
        </StyledSideBarIcon>
      </StyledMenu>
    </StyledContainer>
  );
};

const StyledMenuOptionCompanies = styled.div`
  display: flex;
  z-index: 1;
  position: absolute;
  top: 4rem;
`;

const StyledContainer = styled.div<StyledHeaderLogoProps>`
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  transition: width 0.5s ease-out;
  padding: 2rem 1rem;
  width: ${({ $sideBarSize }) => ($sideBarSize === "L" ? "14.375rem" : "6rem")};
`;

const StyledTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const StyledMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
`;

const StyledSideBarIcon = styled.div`
  width: 100%;
`;

const StyledWordBreak = styled.div`
  display: flex;
  word-break: break-all;
`;

const StyledJustify = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledCompaniesTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: ${colors["colors/text/lightGrey"]};
  ${typographies["Body/XXS"]};
`;
